import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.1_react-dom@19.0.0-rc-69d4b800-20241021_react@19.0.0-rc-69d4b800-20241021__react@19.0.0-rc-69d4b800-20241021/node_modules/next/font/google/target.css?{\"path\":\"../../packages/ui/components/website/hero.tsx\",\"import\":\"Architects_Daughter\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"architectsDaughter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/packages/ui/components/website/technologies.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/align.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/benefits-people.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/deliver.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/landing_photo.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/ui/public/images/task-list.jpg");
