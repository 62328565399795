/* eslint-disable -- external library */

'use client'

// @ts-ignore
// TODO: upgrade or remove swiper
import Swiper, { Autoplay } from 'swiper'
import { useEffect } from 'react'
import 'swiper/swiper.min.css'
import type { StaticImageData } from 'next/image'
import Image from 'next/image'
import Skills01 from '../../public/images/skills/python.png'
import Skills02 from '../../public/images/skills/redhat.png'
import Skills03 from '../../public/images/skills/kubernetes.png'
import Skills04 from '../../public/images/skills/gcp.png'
import Skills05 from '../../public/images/skills/terraform.png'
import Skills06 from '../../public/images/skills/openstack.png'
import Skills07 from '../../public/images/skills/azure.png'
import Skills08 from '../../public/images/skills/ansible.png'
import Skills09 from '../../public/images/skills/github.png'
import Skills10 from '../../public/images/skills/bash.png'
import Skills11 from '../../public/images/skills/docker.png'
import Skills12 from '../../public/images/skills/prisma.png'
import Skills13 from '../../public/images/skills/paloalto.png'
import Skills14 from '../../public/images/skills/devsecops.png'
import Skills15 from '../../public/images/skills/splunk.png'
import Skills16 from '../../public/images/skills/js.png'
import Skills17 from '../../public/images/skills/aws.png'

Swiper.use([Autoplay])

export default function Technologies(): JSX.Element {
  useEffect(() => {
    const swiper = new Swiper('.technologies-carousel', {
      slidesPerView: 'auto',
      spaceBetween: 16,
      centeredSlides: true,
      loop: true,
      speed: 5000,
      noSwiping: true,
      noSwipingClass: 'swiper-slide',
      autoplay: {
        delay: 0,
        disableOnInteraction: true,
      },
    })
  }, [])

  return (
    <section className="bg-gray-900">
      <div className="py-12 md:py-20">
        {/* <!-- Section header --> */}
        <div className="max-w-3xl mx-auto text-center pb-12 md:pb-16">
          <h2 className="h4 font-cabinet-grotesk text-gray-100">
            Our certifications and skills include
          </h2>
        </div>

        <div className="technologies-carousel relative before:absolute before:inset-0 before:w-32 before:z-10 before:pointer-events-none before:bg-gradient-to-r before:from-gray-900 after:absolute after:inset-0 after:left-auto after:w-32 after:z-10 after:pointer-events-none after:bg-gradient-to-l after:from-gray-900">
          <div className="swiper-wrapper !ease-linear select-none">
            {/* <!-- Carousel items --> */}
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 01"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills01 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 02"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills02 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 03"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills03 as StaticImageData}
              />
            </div>

            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 04"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills04 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 05"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills05 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 06"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills06 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 07"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills07 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 08"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills08 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 09"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills09 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 10"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills10 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 11"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills11 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 12"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills12 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 13"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills13 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 14"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills14 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 15"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills15 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 16"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills16 as StaticImageData}
              />
            </div>
            <div className="swiper-slide !h-32 !w-32 bg-gray-800 rounded-2xl flex items-center justify-center group">
              <Image
                alt="Skill 17"
                className="opacity-60 group-hover:opacity-100 transform duration-500 ease-in-out p-4"
                src={Skills17 as StaticImageData}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
/* eslint-enable */
